
html {
    scroll-behavior: smooth;
}

.boxWithBackground::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*background-image: url("images/R4.png"); !* Replace with your image path *!*/
    /*background-repeat: repeat;*/
    /*background-size: 150vw;*/
    /*transform: rotate(180deg); !* Set your desired rotation *!*/
    /*transition: transform 0.5s ease-in-out;*/
    z-index: -1;

    background-image: url("images/R9.png");
    background-size: 100vw;
    background-position: center;
    /*background-blend-mode: color;*/
    /*background-attachment: fixed;*/
    /*background*/
    /*transform: rotate(0deg);*/
    /*-webkit-backdrop-filter: blur(100px);*/
}